@import @sassConfig

.button
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  padding: 10px 24px
  font: 400 14px $font
  line-height: 143%
  color: $white
  background: $blue
  border-radius: 8px
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s
  min-width: 128px
  height: 40px

  &:hover
    background: #2297FF

  &:active
    background: #005DAF
    color: $white

  &:disabled
    background: #C5D1DB
    color: #95A9B9
    pointer-events: none

  &:focus
    box-shadow: 0 0 0 2pt #BBDEFF

  &--ghost
    padding: 2px
    background: none
    color: $blue
    border-radius: 0

    &:hover
      background: none
      color: #2297FF

    &:active
      background: none
      color: #005DAF

    &:disabled
      background: none
      color: #C5D1DB

  &--outlined
    border: 2px solid $blue
    color: $blue
    background: $white

    &:hover
      background: #2297FF
      border-color: #2297FF
      color: $white

    &:disabled
      border: none
      background: none
      color: #C5D1DB
      pointer-events: none

  &--outlined-borderless
    color: $blue
    background: $white

    &:hover
      background: #2297FF
      color: $white

  &--icon
    padding: 10px 14px
    justify-content: flex-start

    &.button--ghost
      padding: 0

    .content
      display: flex
      align-items: end
      justify-content: flex-start
      margin: 0 -4px

    .text
      padding: 0 4px

    .icon
      padding: 0 4px

  &--small
    padding: 10px
    min-width: 92px
    height: 32px
    font: 400 12px $font
    line-height: 100%
    border-radius: 4px

    .icon
      width: 28px
      height: 16px
      max-width: 28px
      min-width: 28px
      max-height: 16px
      min-height: 16px

      svg
        width: 16px
        height: 16px

.content
  display: flex
  align-items: center
  justify-content: flex-start

.text
  display: block

.icon
  width: 32px
  height: 24px
  max-width: 32px
  min-width: 32px
  max-height: 24px
  min-height: 24px
  display: flex
  align-items: end
  justify-content: center
