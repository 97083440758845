@import "@sassConfig"

@keyframes animBlock
  0%
    opacity: 0
  100%
    opacity: 1

.window
  position: fixed
  width: 100%
  top: 0
  bottom: 0
  right: -100%
  z-index: 100
  opacity: 0
  transition: right .3s ease, opacity .3s ease, top 0.3s ease
  overflow: hidden
  display: flex
  justify-content: flex-end
  pointer-events: none

  &.active
    right: 0
    opacity: 1
    transition: right .3s ease, opacity .3s ease, top .3s ease
    pointer-events: auto

    .overlay
      display: block
      animation-name: animBlock
      animation-duration: 0.2s
      animation-fill-mode: both
      animation-timing-function: ease
      animation-delay: 0.2s

.overlay
  position: fixed
  top: 0
  bottom: 0
  right: 0
  left: 0
  background: rgba(14, 34, 53, .35)
  overflow: hidden
  z-index: 101
  display: none
  backdrop-filter: blur(1px)

.wrapper
  display: flex
  justify-content: space-between
  flex-direction: column
  width: 100%
  max-width: 1000px
  background: $white
  position: relative
  z-index: 102
  max-height: 100vh

  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    bottom: 0
    right: 0
    background: $white

.content
  position: relative
  z-index: 1
  overflow-y: auto
  +scrollbar

.bottom
  padding: 30px 60px
  background: $gray5
  z-index: 0
  border-top: 1px solid $gray4

  &.withSaveButton

    display: flex
    align-items: center

    & .button:first-child
      margin-right: 16px

