@import @sassConfig

.wrapper
	padding: 60px 44px 60px 60px
	overflow: hidden

.title
	+font-header1
	color: $color
	margin-bottom: 40px

.icon
	position: relative

.unread
	position: absolute
	display: flex
	justify-content: center
	align-items: center
	top: -6px
	right: 3px
	transform: translate(50%, 0)
	background: $alert-red
	font-size: 10px
	line-height: 10px
	border: 1px solid $white
	min-width: 18px
	height: 18px
	border-radius: 18px
	padding: 0 2px
	color: $white
