@import @sassConfig

.wrapper
  padding: 160px 80px
  +mobile-sm
    padding: 80px 40px

.number
  font: 400 100px/150% $font
  color: $gray4

.title
  font: 400 36px/125% $font
  color: $color
  margin-bottom: 12px

.subtitle
  font: 400 18px/150% $font
  color: $color
  margin-bottom: 40px
  max-width: 560px

.button
