@import @sassConfig
.wrapper
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

.logo
    margin-bottom: 20px
    transform: translateX(-10px)

.message
    max-width: 100%
    font: 400 20px/150% $font
    color: $dark-blue
    text-align: center

    & a
        color: $blue


