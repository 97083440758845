@import @sassConfig

.item
	background: #F9FBFC
	border-radius: 12px
	margin-bottom: 4px
	padding: 20px 64px 20px 20px
	position: relative
	border: 1px solid #F9FBFC
	transition: background-color .8s

	a
		color: $blue

		&:hover
			color: $dark-blue

	&.read
		background-color: $white
		border: 1px solid $medium-gray

	&:last-child
		margin-bottom: 0

.cross
	position: absolute
	top: 26px
	right: 26px
	transform: translate(50%, -50%)
	cursor: pointer
	transition: transform .3s

	&:hover
		transform: translate(50%, -50%) rotate(90deg)

.date
	+font-description
	color: $gray2
	margin-bottom: 8px

.filesTitle
	+font-description
	color: $gray2
	margin-top: 20px
	margin-bottom: 8px

.notification
	+font-paragraph
	color: $color

