@import @sassConfig

.footer
    padding: 16px 120px
    background: $white
    display: flex
    align-items: center
    justify-content: space-between
    +desktop-sm
        padding: 16px 32px
    +tablet-mobile
        padding: 16px 32px
        flex-direction: column
        align-items: flex-start

.left
    max-width: 201px

    +tablet-mobile
        max-width: 100%
        margin: 0 0 20px 0

.title,
.doc
    font: 400 16px/125% $font
    color: $dark-blue
    white-space: wrap
    +desktop-sm
        font-size: 14px

.docs
    display: flex
    align-items: center
    justify-content: space-between
    margin: 0 -40px
    +desktop-sm
        margin: 0 -10px
    +tablet
        flex-direction: column
        align-items: flex-start
    +tablet-mobile
        flex-direction: column
        align-items: flex-start
        margin: 0

.doc
    padding: 0 40px
    color: $gray2

    &:last-child
        +tablet-mobile
            margin-bottom: 0

    +desktop-sm
        padding: 10px
    +tablet-mobile
        padding: 0
        margin-bottom: 10px

